.snackbar-success > .mdc-snackbar__surface {
  background: #66bb6a !important;
}

.snackbar-warning > .mdc-snackbar__surface {
  background: #ffa726 !important;
}

.snackbar-error > .mdc-snackbar__surface {
  background-color: #f44336 !important;
}
