@use '@angular/material' as mat;
@use "assets/theme/du-theme";

@include mat.core();

:root {
  @include mat.all-component-themes(du-theme.$light-theme);
  @include mat.typography-hierarchy(du-theme.$light-theme);
}

html, body {
  height: 100%;
}

body {
  --mat-app-background-color: rgb(249, 249, 249);;

  .dark-theme {
    --mat-app-background-color: rgb(249, 249, 249); //When support for dark-theme change this
  }
  margin: 0;
}

.overview-header-button-toggle > .mat-pseudo-checkbox {
  display: none !important;
}

@for $i from 1 through 10 {
  .dense-#{$i} {
    @include mat.all-component-densities(-$i);
  }
}


.grid-container {
  display: grid;
  grid-template-columns: repeat(12, 1fr);

  gap: var(--grid-gap-size, 16px);

  @for $i from 1 through 12 {
    .col-span-#{$i} {
      grid-column: span #{$i};
    }

    @media (max-width: 840px) {
      .col-span-sm-#{$i} {
        grid-column: span #{$i};
      }
    }

    @media (max-width: 1200px) {
      .col-span-md-#{$i} {
        grid-column: span #{$i};
      }
    }

    @media (max-width: 1400px) {
      .col-span-xl-#{$i} {
        grid-column: span #{$i};
      }
    }

    @media (max-width: 1600px) {
      .col-span-xxl-#{$i} {
        grid-column: span #{$i};
      }
    }

    @media (min-width: 1600px) {
      .col-span-xxxl-#{$i} {
        grid-column: span #{$i};
      }
    }
  }
}





.form-field-transparent-background .mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background: transparent;
}

.detail-header-button-back.mat-mdc-icon-button {
  border-radius: 4px;
  border: solid 1px rgba(0, 0, 0, 0.12);
}

.detail-header-button-back.mat-mdc-icon-button .mat-mdc-button-persistent-ripple {
  border-radius: 4px !important;
}

.layout-column {
  display: flex;
  flex-direction: column;
}

.layout-row {
  display: flex;
  flex-direction: row;
}

.layout-align-space-between-center {
  justify-content: space-between;
  align-items: center;
}

.layout-row-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.layout-align-start-center {
  justify-content: flex-start;
  align-items: center;
}

.license-wizard-stepper {
  .mat-horizontal-content-container {
    margin-top: 16px !important;
    padding-bottom: 0 !important;
  }
}

.language-selector-container {
  margin-top: 16px;
  display: flex;
  justify-content: center;

  .language-selector {
    display: flex;
    gap: 8px;

    .language-item {
      cursor: pointer;
    }

    .selected {
      text-decoration: underline;
    }
  }
}

.form-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.form-field {
  flex: 1;
  max-width: 100%;
}
